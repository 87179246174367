import React, { useEffect, useState, useRef } from 'react';
import VendorFilter from './vendor-filter';
import VendorAddRemoveCard from './vendor-add-and-remove-cards';
import TenderAPI from '../../../apis/tender';

const VendorCard = ({
	selectedVendorList,
	handleSelectVendor,
}) => {

  const [companyName, setCompany] = useState("")
  const [OBCReady, setOBCReady] = useState("")
  const [plrd, setPlrd] = useState([])
  const [VendorList, setVendorList] = useState([])
  const [loading, setLoading] = useState(false);
  const [filterPayload, setFilterPayload] = useState({
    plrd: [],
    gradeAmount: "",
    manpower: "",
    obc_ready: "",
  })
  const [page, setPage] = useState(-1)
  const observerRef = useRef(); 
  // const [maxPage, setMaxPage] = useState(1)
  
  const onChangeCompName = (value) => {
    setCompany(value)
  }

  const onApplyFilter = (value) => {
    setFilterPayload({
      ...filterPayload,
      ...value
    })
  }

  const FetchVendorList = async  (payload = {}) => {
    setLoading(true);
    try {

      if (companyName) payload.n = companyName;
      const {obc_ready, plrd, gradeAmount, manpower} = filterPayload
      if (obc_ready !== null &&  obc_ready !== '') payload.obc_ready = obc_ready;
      if (plrd && plrd.length > 0) payload.plrd = encodeURIComponent(JSON.stringify(plrd));
      if (manpower && manpower !== '') payload.mnpwr = manpower;
      if (gradeAmount && gradeAmount !== '') payload.grd = gradeAmount;

      payload.page = page;
      const response = await TenderAPI.getVendors(payload);
      const {data, pagination} = response
      let updData = data.map(v => {return {id: v._id, company: v.company, name: v.name, email: v.email}})
      updData = updData.filter(vendor => 
        !selectedVendorList.some(selected => selected.id === vendor.id)
      );
      setVendorList(updData || [])

    } catch (e) {
      console.log(e)
    }
    setLoading(false);
  }

  const onRemoveVendor = (vendor) => {
    setVendorList([...VendorList, vendor]);
    handleSelectVendor(selectedVendorList.filter(v => v.id !== vendor.id)); 
  }

  const onSelectVendor = (vendor) => {
    handleSelectVendor([...selectedVendorList, vendor]);
    setVendorList(VendorList.filter(v => v.id !== vendor.id)); 
  };


  useEffect(() => {
    FetchVendorList({page})
  }, [page, companyName, filterPayload])

  // useEffect(() => {
  //   console.log(page, "here")
  //   if(page <= maxPage) {
  //     FetchVendorList({page})
  //   }
  // }, [page])

  // const handleObserver = (entities) => {
  //   const target = entities[0]
  //   if (target.isIntersecting && !loading) {
  //     setPage((prevPage) => prevPage + 1)
  //   }
  // }

  // useEffect(() => {
  //   const options = {
  //     root: null,
  //     rootMargin: "20px",
  //     threshold: 1.0
  //   };

  //   const observer = new IntersectionObserver(handleObserver, options);
  //   if (observerRef.current) {
  //     observer.observe(observerRef.current); 
  //   }

  //   return () => {
  //     if (observerRef.current) {
  //       observer.unobserve(observerRef.current);
  //     }
  //   };
  // }, [loading]);

	return (
		<div className="vendor-card">
			<VendorFilter
        handleFilter={onApplyFilter}
      />
      <VendorAddRemoveCard
        selectedVendorList={selectedVendorList}
        vendors={VendorList}
        onSelectVendor={onSelectVendor}
        onRemoveVendor={onRemoveVendor}
        companyName={companyName}
        onChangeCompName={onChangeCompName}
        isLoading={loading}
        observerRef={observerRef}
      />
		</div>
	)
}

export default VendorCard