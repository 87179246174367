import React, { useEffect, useState } from "react";
import "./submit-bid.scss";

import Loader from "../../common/loading/loading";
import ToasterComponent from "../../common/toaster/toaster";
import Accordion from "react-bootstrap/Accordion";
import AwsApi from "../../../apis/aws";
import TenderAPI from "../../../apis/tender";
import AppConfirm from "../../common/app-confirm/app-confirm";
import CommonUtils from "../../../utility/common-utilities";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessage,
  faFileAlt,
  faEye,
  faSpinner,
  faEdit,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { getImageSource } from "../../../utility/image-source";
import ImageConstants from "../../../constants/image-constants";
import SessionCache from "../../routes/session-cache";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { AccordionItem } from "react-bootstrap";

// setup the link for download button word

// let items = [
//   {
//     type: "P_TYPE1",
//     title: "Analysis of risk and operational considerations",
//     documentKey: "",
//     documentUrl: "",
//   },
//   {
//     type: "P_TYPE2",
//     title:
//       "Operational model (manpower, processes, infrastructure, technology)",
//     documentKey: "",
//     documentUrl: "",
//   },
//   {
//     type: "P_TYPE3",
//     title: "Technology solutions",
//     documentKey: "",
//     documentUrl: "",
//   },
//   {
//     type: "P_TYPE4",
//     title: "Resource plan",
//     documentKey: "",
//     documentUrl: "",
//   },
//   {
//     type: "P_TYPE5",
//     title:
//       "Transition Plan (how services & technology will be implemented during transition)",
//     documentKey: "",
//     documentUrl: "",
//   },
// ];

// let companyDocsItems = [
//   {
//     type: "C_TYPE1",
//     title: "Copy of audited financial statements",
//     documentKey: "",
//     documentUrl: "",
//   },
//   {
//     type: "C_TYPE2",
//     title: "latest business profile by ACRA",
//     documentKey: "",
//     documentUrl: "",
//   },
//   {
//     type: "C_TYPE3",
//     title: "Testimonials and Project References",
//     documentKey: "",
//     documentUrl: "",
//   },
//   {
//     type: "C_TYPE4",
//     title: "Training & Competency Assessment Plans",
//     documentKey: "",
//     documentUrl: "",
//   },
//   {
//     type: "C_TYPE5",
//     title: "Certifications, expertise attained",
//     documentKey: "",
//     documentUrl: "",
//   },
//   {
//     type: "C_TYPE6",
//     title: "HR / Company Policies on Working Conditions and Officer Welfare",
//     documentKey: "",
//     documentUrl: "",
//   },
// ];
const CreateBid = (props) => {
  const [isLoading, setLoader] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(props.isViewOnly);
  const [showToaster, setToaster] = useState(false);
  const [toaster, setToasterObj] = useState({});
  const [tender, setTender] = useState(props.tender || {});
  const [payload, setPayload] = useState({});
  const [proposalDocs, setProposalDocs] = useState([]);
  const [companyDocs, setCompanyDocs] = useState([]);
  const [errorKey, setErrorKey] = useState("");
  const [errorIndex, setErrorIndex] = useState("");
  const [previewUrl, setPreview] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showAwardConfirmModal, setAwardShowConfirmModal] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const UserInfoDownloadLink = [
    "https://userinfo-template.s3.ap-southeast-1.amazonaws.com/Risk+Analysis.docx",
    "https://userinfo-template.s3.ap-southeast-1.amazonaws.com/Operational+Model.docx",
    "https://userinfo-template.s3.ap-southeast-1.amazonaws.com/Technology+Solutions.docx",
    "https://userinfo-template.s3.ap-southeast-1.amazonaws.com/Transition+Plan.docx",
    "https://userinfo-template.s3.ap-southeast-1.amazonaws.com/Data+Collection+Plan.docx",
  ];

  const getBuyerTendersDetails = (payload = {}) => {
    payload.id = tender?.tenderNumber;
    console.log(payload);
    TenderAPI.getTenderDetails(payload)
      .then((response) => {
        setProposalDocs(response.questioneries.proposal);
        setCompanyDocs(response.questioneries.comapny_particualr);
      })
      .catch((err) => {
        setLoader(false);
        // enableToaster("error", "Oops, Something went wrong");
      });
  };

  const getIttTenderDetails = (payload = {}) => {
    console.log(payload);
    payload.id = props?.tender?._id;
    TenderAPI.getIttTenderSubDetail(payload)
      .then((response) => {
        setTender(response);
        setPayload(response?.price || {});
        setProposalDocs(response?.docs?.proposalDocs || []);
        setCompanyDocs(response?.docs?.companyDocs || []);
      })
      .catch((err) => {
        setLoader(false);
        // enableToaster("error", "Oops, Something went wrong");
      });
  };

  
  const handlePayloadChange = (value, key) => {
    let payloadObj = { ...payload };
    payloadObj[key] = value;
    setPayloadObject(payloadObj);
    console.log(payload);
  };

  const setPayloadObject = (payloadObj) => {
    setPayload(payloadObj);
  };

  const onSelectFile = (event, index, key) => {
    let file = event.target.files[0];
    if (!file) return;
    let fileName = file.name.split(".");
    let extention = fileName[fileName.length - 1];
    fileName.length = fileName.length - 1;
    let name = `${fileName.join("")}_${new Date().getTime()}.${extention}`;
    let payload = {
      fileName: name,
      type: "vendor",
      tid: tender?.tenderNumber,
    };
    setLoader(true);
    setErrorKey("");
    AwsApi.getSignedUrl(payload)
      .then((response) => {
        uploadFile(response.signedUrl, file, index, key);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const uploadFile = (signedUrl, file, index, key) => {
    AwsApi.uploadFileAwsS3(signedUrl, file)
      .then((response) => {
        let urls = signedUrl.split("?");
        let splittedSignedUrl = urls[0].split(".com/");
        let image = splittedSignedUrl[splittedSignedUrl.length - 1];

        if (key == "company") {
          companyDocs[index][`documentUrl`] = urls[0];
          companyDocs[index][`documentKey`] = image;
          setCompanyDocs([...companyDocs]);
        } else {
          proposalDocs[index][`documentUrl`] = urls[0];
          proposalDocs[index][`documentKey`] = image;
          setProposalDocs([...proposalDocs]);
        }

        setLoader(false);
        enableToaster("success", "Uploads Done");
      })
      .catch((error) => {
        setLoader(false);
        enableToaster("error", "Oops, Something went wrong");
      });
  };

  const enableToaster = (type, message) => {
    setToasterObj({ type, message });
    setToaster(true);
    setTimeout(() => hideToaster(), 3000);
  };

  const hideToaster = () => {
    setToaster(false);
  };

  const submitBidDetails = () => {
    let obj = buildPayload();
    let isError = errorValidation(obj);

    if (isError) {
      enableToaster("error", "Validation Error");
      return;
    }
    setShowConfirmModal(true);
  };

  const onClickAwardButton = () => {
    setAwardShowConfirmModal(true);
  };

  const closeConfirm = () => {
    setShowConfirmModal(false);
    setAwardShowConfirmModal(false);
  };

  const confirmSubmitBirDetails = () => {
    let obj = buildPayload();
    let isError = errorValidation(obj);

    if (isError) {
      enableToaster("error", "Validation Error");
      return;
    }
    setLoader(true);

    if (isEdit) {
      let uploadPayload = {
        id: tender._id,
        docs: {
          companyDocs: obj.companyDocs,
          proposalDocs: obj.proposalDocs,
        },
        price: {
          contractDuration: +obj.contractDuration,
          infrastructureImprovement: +obj.infrastructureImprovement,
          operationalExpense: +obj.operationalExpense,
          // addOnOperationalExpenditure: +obj.addOnOperationalExpenditure,
        },
      };
      TenderAPI.updateIttTenderDetail(uploadPayload)
        .then((response) => {
          setLoader(false);
          enableToaster("success", "Document updated successfully");
          setEdit(false);
          tender.price = uploadPayload.price;
          tender.docs = uploadPayload.docs;
          setTender(tender);
          closeConfirm();
        })
        .catch((error) => {
          enableToaster("error", "Oops, Something went wrong");
          setLoader(false);
        });
    } else {
      TenderAPI.ITTTenderSubmission(obj)
        .then((response) => {
          setLoader(false);
           enableToaster("success", "Document submitted successfully");
          props.back();
          window.location.href = response.data.paymentIntent;
        })
        .catch((error) => {
          // enableToaster("error", "Oops, Something went wrong");
          setLoader(false);
        });
    }
  };

  const buildPayload = () => {
    // return;
    let obj = {
      tenderNumber: tender?.tenderNumber,
      contractDuration: payload?.contractDuration,
      operationalExpense: payload?.operationalExpense,
      infrastructureImprovement: payload?.infrastructureImprovement,
      // addOnOperationalExpenditure: payload?.addOnOperationalExpenditure,
      proposalDocs,
      companyDocs,
    };

    return obj;
  };

  const errorValidation = (obj) => {
    if (!obj.contractDuration) {
      setErrorKey("contractDuration");
      return true;
    }
    if (!obj.infrastructureImprovement) {
      setErrorKey("infrastructureImprovement");
      return true;
    }

    if (!obj.operationalExpense) {
      setErrorKey("operationalExpense");
      return true;
    }
    // if (!obj.addOnOperationalExpenditure) {
    //   setErrorKey("addOnOperationalExpenditure");
    //   return true;
    // }

    for (let i = 0; i < proposalDocs.length; i++) {
      if (proposalDocs[i].ans === true) {
        if (!proposalDocs[i].documentKey) {
          setErrorKey("proposalDocs");
          setErrorIndex(i);
          return true;
        }
      }
    }

    for (let i = 0; i < companyDocs.length; i++) {
      if (companyDocs[i].ans === true) {
        if (!companyDocs[i].documentKey) {
          setErrorKey("companyDocs");
          setErrorIndex(i);
          return true;
        }
      }
    }

    setErrorKey("");
    return false;
  };

  const confirmAwardTender = () => {
    let obj = {
      tid: tender?.tenderNumber,
      vid: tender?.vendorUserId,
    };

    setLoader(true);
    setAwardShowConfirmModal(false);
    TenderAPI.awardTender(obj)
      .then((response) => {
        setLoader(false);
        enableToaster("success", "Awarded successfully");
        props.back(true);
      })
      .catch((error) => {
        // enableToaster("error", "Oops, Something went wrong");
        setLoader(false);
      });
  };

  const onClickDocumentPreview = (item, index, key) => {
    if (key === "proposalDocs") {
      let proposalDocsObj = [...proposalDocs];
      item.isLoading = true;
      proposalDocsObj[index] = item;

      AwsApi.getSignedUrlToView({ url: item.documentKey })
        .then((response) => {
          item.isLoading = true;
          item.loadingKey = "";
          proposalDocsObj[index] = item;
          setProposalDocs(proposalDocsObj);
          setPreview(response.signedUrl);
        })
        .catch((error) => {
          item.isLoading = true;
          proposalDocsObj[index] = item;
          setProposalDocs(proposalDocsObj);
        });
    } else {
      let companyDocsObj = [...companyDocs];
      item.isLoading = true;
      companyDocsObj[index] = item;

      AwsApi.getSignedUrlToView({ url: item.documentKey })
        .then((response) => {
          item.isLoading = true;
          item.loadingKey = "";
          companyDocsObj[index] = item;
          setCompanyDocs(companyDocsObj);
          setPreview(response.signedUrl);
        })
        .catch((error) => {
          item.isLoading = true;
          companyDocsObj[index] = item;
          setCompanyDocs(companyDocsObj);
        });
    }
  };

  const closePreview = () => {
    setLoader(false);
    setPreview("");
  };

  const renderPreviewDocument = () => {
    return (
      <div className="vendor-tender-detail text shadow rounded">
        <div className="row m-2 pt-4">
          <div className="col">
            <button onClick={closePreview} className="btn btn-light">
              <i class="bi bi-arrow-left-short"></i>Back
            </button>
          </div>
        </div>
        <hr />
        <iframe className="preview-iframe" src={previewUrl} />
      </div>
    );
  };

  const handleClickEdit = () => {
    setEdit(!isEdit);
    // setIsViewOnly(!isViewOnly)
  };

  const onRemoveDocument = (e, index, key) => {
    e.stopPropagation();

    if (key == "proposalDocs") {
      proposalDocs[index][`documentUrl`] = "";
      proposalDocs[index][`documentKey`] = "";
      setProposalDocs([...proposalDocs]);
    } else {
      companyDocs[index][`documentUrl`] = "";
      companyDocs[index][`documentKey`] = "";
      setCompanyDocs([...companyDocs]);
    }
  };

  useEffect(() => {
    if (props.isViewOnly) {
      getIttTenderDetails();
    }
    getBuyerTendersDetails();
  }, []);

  return (
    <>
      {!isLoading && previewUrl ? (
        renderPreviewDocument()
      ) : (
        <div className="submit-bid py-2 text shadow rounded">
          {isLoading ? <Loader /> : ""}
          <div className="row m-2">
            <div className="col-4">
              <button onClick={props.back} className="btn btn-light">
                <i class="bi bi-arrow-left-short"></i>Back
              </button>
            </div>
            {SessionCache.getUserType() === "VENDOR" &&
            props.showEdit &&
            tender?.isEdit ? (
              <div className="col">
                <button
                  onClick={handleClickEdit}
                  type="button"
                  className="btn btn-sm btn-primary btn-sm-in-w obx-btn-color float-end mr-2"
                >
                  {" "}
                  {!isEdit ? (
                    <span>
                      {" "}
                      <FontAwesomeIcon icon={faEdit} /> &nbsp;Edit
                    </span>
                  ) : (
                    "Done"
                  )}{" "}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          <hr />
          <div className="position-relative m-4">
            <div className="pt-4">
              <Accordion
                defaultActiveKey={isViewOnly ? ["1"] : ["0", "1", "2"]}
                alwaysOpen
              >
                {!props.isAward ? (
                  <Accordion.Item eventKey={"0"}>
                    <Accordion.Header>
                      <div className="">
                        <div className="obx-ls-t">
                          {" "}
                          {tender?.tenderNumber} – Bid Submission{" "}
                        </div>
                        <div className="pt-2">
                          This is the proposal submission page for Tender #
                          {tender.tenderNumber}. 
                        </div>
                      </div>
                    </Accordion.Header>
                    <AccordionBody>
                    <div className="pt-2">
                          Please submit a detailed proposal and indicate your tender price over the contract duration.
                        </div>
                    </AccordionBody>
                  </Accordion.Item>
                ) : (
                  ""
                )}

                <Accordion.Item eventKey={"1"}>
                  <Accordion.Header>
                  <div className="">
                      <div className="obx-ls-t">Faclity Details</div>
                    </div>
                  </Accordion.Header>
                 <Accordion.Body>
               
                 <div className=''>
                <div className='row  obx-ls-st'>
                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='mb-2'>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Facility Name : <b>{tender?.facilityName}</b>{" "}
                      </label>
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='mb-2'>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Facility Type :{" "}
                        <b>
                          {CommonUtils.camelCase(tender?.facilityType || "")}
                        </b>{" "}
                      </label>
                    </div>
                  </div>

                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='mb-2'>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Street Address : <b>{tender?.streetAddress}</b>{" "}
                      </label>
                    </div>
                  </div>

                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='mb-2'>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Facility Postal Code :{" "}
                        <b>{tender?.facilityPostalCode}</b>{" "}
                      </label>
                    </div>
                  </div>              

                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='mb-2'>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Status : <b>{tender?.status}</b>{" "}
                      </label>
                    </div>
                  </div>

                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='mb-2'>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Services :{" "}
                        {tender?.selectedServices?.map((service) => (
                          <span className='me-2 badge text-bg-light obx-text-dgray text'>
                            {service?.title}
                          </span>
                        ))}
                      </label>
                    </div>
                  </div>

                  <div className='mb-4'></div>

                  <div className=''>
                    <span className='h6 obx-ls-t'>Important Dates</span>
                  </div>
                  <div>
                    <hr />
                  </div>

                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='mb-1'>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Publication Date :
                        <b>
                          {CommonUtils.datePipe(
                            tender?.terms?.publicationDate,
                            "dd/MM/yyyy"
                          )}
                        </b>
                      </label>
                    </div>
                  </div>

                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className=''>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Submission Deadline Date :
                        <b>
                          {CommonUtils.datePipe(
                            tender?.terms?.submissionDeadlineDate,
                            "dd/MM/yyyy"
                          )}
                        </b>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

                 </Accordion.Body>
                </Accordion.Item>
                
                <Accordion.Item eventKey={"2"}>
                  <Accordion.Header>
                    <div className="">
                      <div className="obx-ls-t">Submission:</div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div>
                        <div className="obx-ls-t"> A. Price submission</div>
                        <div className="px-3">
                          <div className="pt-2">
                            <label>
                              1. Enter your total bid price over the contract
                              duration:{" "}
                            </label>
                            {isViewOnly && !isEdit ? (
                              <b> $ {tender?.price?.contractDuration}</b>
                            ) : (
                              <div className="pt-2 px-4">
                                <input
                                  placeholder="$"
                                  type="number"
                                  className={`form-control custom-input-bid ${
                                    errorKey === "contractDuration"
                                      ? "invalid-input"
                                      : ""
                                  }`}
                                  value={payload.contractDuration}
                                  onChange={(event) =>
                                    handlePayloadChange(
                                      event?.target?.value,
                                      "contractDuration"
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div>

                          <div className="pt-4">
                            <label>
                              2. Enter the cost for technology over the contract duration:
                            </label>
                            {isViewOnly && !isEdit ? (
                              <b>
                                {" "}
                                $ {tender?.price?.infrastructureImprovement}
                              </b>
                            ) : (
                              <div className="pt-2 px-4">
                                <input
                                  type="number"
                                  placeholder="$"
                                  className={`form-control custom-input-bid ${
                                    errorKey === "infrastructureImprovement"
                                      ? "invalid-input"
                                      : ""
                                  }`}
                                  value={payload.infrastructureImprovement}
                                  onChange={(event) =>
                                    handlePayloadChange(
                                      event?.target?.value,
                                      "infrastructureImprovement"
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div>

                          <div className="pt-4">
                            <label>
                              3. Enter the cost for operational
                              expenses over the contract duration:
                            </label>
                            {isViewOnly && !isEdit ? (
                              <b> $ {tender?.price?.operationalExpense}</b>
                            ) : (
                              <div className="pt-2 px-4">
                                <input
                                  type="number"
                                  className={`form-control custom-input-bid ${
                                    errorKey === "operationalExpense"
                                      ? "invalid-input"
                                      : ""
                                  }`}
                                  placeholder="$"
                                  value={payload.operationalExpense}
                                  onChange={(event) =>
                                    handlePayloadChange(
                                      event?.target?.value,
                                      "operationalExpense"
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div>

                          {/* <div className="pt-4">
                            <label>
                              4.Enter the value of any optional or add-on
                              operational expenditure:
                            </label>
                            {isViewOnly && !isEdit ? (
                              <b>
                                {" "}
                                $ {tender?.price?.addOnOperationalExpenditure}
                              </b>
                            ) : (
                              <div className="pt-2 px-4">
                                <input
                                  type="number"
                                  className={`form-control custom-input-bid ${
                                    errorKey === "addOnOperationalExpenditure"
                                      ? "invalid-input"
                                      : ""
                                  }`}
                                  value={payload.addOnOperationalExpenditure}
                                  onChange={(event) =>
                                    handlePayloadChange(
                                      event?.target?.value,
                                      "addOnOperationalExpenditure"
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div> */}
                        </div>
                      </div>
                      <div className="pt-4">
                        <div className="obx-ls-t"> B. Document submission</div>
                        <div className="px-3 pt-4">
                          <div>
                            <div className="obx-ls-t"> a. Proposal</div>
                            <div className="table-responsive">
                              <table className="mt-3 border table">
                                <thead className="text-center border-bottom">
                                  <tr>
                                    <th scope="col" className="p-2" colSpan={1}>
                                      S/N
                                    </th>
                                    <th className="p-2" colSpan={5}>
                                      Description
                                    </th>
                                    <th className="p-2" colSpan={1}>
                                      Template
                                    </th>
                                    <th className="p-2" colSpan={2}>
                                      Upload
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {proposalDocs.length > 0
                                    ? proposalDocs.map((item, index) => (
                                        <tr className="border-bottom">
                                          <td
                                            className="text-center p-2"
                                            colSpan={1}
                                          >
                                            {index + 1}
                                          </td>
                                          <td
                                            className="p-2 border-start"
                                            colSpan={5}
                                          >
                                            {item.ans == true ? (
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                [Required]
                                              </span>
                                            ) : null}{" "}
                                            {item.question}{" "}
                                          </td>
                                          <td
                                            className={`text-center p-2 ${
                                              index === proposalDocs.length - 1
                                                ? `all_document_upload_hide`
                                                : `""`
                                            }`}
                                            colSpan={1}
                                          >
                                            <a
                                              href={UserInfoDownloadLink[index]}
                                            >
                                              <img
                                                src={`${getImageSource(
                                                  ImageConstants.DOWNLOADWORD
                                                )}`}
                                                style={{ width: "30px" }}
                                                alt="logo"
                                              />
                                            </a>
                                          </td>
                                          <td className="p-2" colSpan={2}>
                                            {isViewOnly && item.documentKey ? (
                                              <div>
                                                <div
                                                  className="doument-preview-wrapper text-center mr-2"
                                                  onClick={() =>
                                                    onClickDocumentPreview(
                                                      item,
                                                      index,
                                                      "proposalDocs"
                                                    )
                                                  }
                                                >
                                                  <div className="doument-preview position-relative">
                                                    <div className="text-center">
                                                      {item.isLoading &&
                                                      item?.loadingKey ===
                                                        "oldDocument" ? (
                                                        <div className="">
                                                          <FontAwesomeIcon
                                                            className="icon "
                                                            spin
                                                            icon={faSpinner}
                                                          />
                                                        </div>
                                                      ) : (
                                                        <div className="">
                                                          <FontAwesomeIcon
                                                            className="icon"
                                                            icon={faFileAlt}
                                                          />
                                                        </div>
                                                      )}
                                                    </div>
                                                    {isEdit ? (
                                                      <FontAwesomeIcon
                                                        onClick={(e) =>
                                                          onRemoveDocument(
                                                            e,
                                                            index,
                                                            "proposalDocs"
                                                          )
                                                        }
                                                        className="remove-icon cursor-pointer"
                                                        icon={faClose}
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            ) : isViewOnly && !isEdit ? (
                                              <div className="text-secondary pt-2">
                                                No file
                                              </div>
                                            ) : (
                                              <input
                                                style={{
                                                  width: "22vw",
                                                  fontSize: "10px",
                                                }}
                                                type="file"
                                                accept=".pdf"
                                                className={`form-control custom-file-input-bid ${
                                                  errorKey === "proposalDocs" &&
                                                  errorIndex === index
                                                    ? "invalid-input"
                                                    : ""
                                                }`}
                                                onChange={(event) =>
                                                  onSelectFile(
                                                    event,
                                                    index,
                                                    "proposal"
                                                  )
                                                }
                                              />
                                            )}
                                          </td>
                                        </tr>
                                      ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                            {/* {proposalDocs.length > 0
                              ? proposalDocs.map((item, index) => (
                                  <div className="px-3 pt-4">
                                    <div className="">
                                      <label>
                                        {item.ans == true ? (
                                          <span
                                            style={{
                                              color: "red",
                                              fontWeight: "600",
                                            }}
                                          >
                                            [Required]
                                          </span>
                                        ) : null}{" "}
                                        {index + 1}. {item.question}{" "}
                                      </label>
                                      <div className="pt-2 px-4">
                                        {isViewOnly && item.documentKey ? (
                                          <div>
                                            <div
                                              className="doument-preview-wrapper text-center mr-2"
                                              onClick={() =>
                                                onClickDocumentPreview(
                                                  item,
                                                  index,
                                                  "proposalDocs"
                                                )
                                              }
                                            >
                                              <div className="doument-preview position-relative">
                                                <div className="text-center">
                                                  {item.isLoading &&
                                                  item?.loadingKey ===
                                                    "oldDocument" ? (
                                                    <div className="">
                                                      <FontAwesomeIcon
                                                        className="icon "
                                                        spin
                                                        icon={faSpinner}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div className="">
                                                      <FontAwesomeIcon
                                                        className="icon"
                                                        icon={faFileAlt}
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                                {isEdit ? (
                                                  <FontAwesomeIcon
                                                    onClick={(e) =>
                                                      onRemoveDocument(
                                                        e,
                                                        index,
                                                        "proposalDocs"
                                                      )
                                                    }
                                                    className="remove-icon cursor-pointer"
                                                    icon={faClose}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        ) : isViewOnly && !isEdit ? (
                                          <div className="text-secondary pt-2">
                                            No file
                                          </div>
                                        ) : (
                                          <input
                                            type="file"
                                            accept=".pdf"
                                            className={`form-control custom-file-input-bid ${
                                              errorKey === "proposalDocs" &&
                                              errorIndex === index
                                                ? "invalid-input"
                                                : ""
                                            }`}
                                            onChange={(event) =>
                                              onSelectFile(
                                                event,
                                                index,
                                                "proposal"
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))
                              : null} */}
                          </div>
                          <div className="pt-4">
                            <div className="obx-ls-t">
                              {" "}
                              b. Company particulars
                            </div>
                            <div className="table-responsive">
                              <table className="mt-3 border table">
                                <thead className="text-center border-bottom">
                                  <tr>
                                    <th scope="col" className="p-2" colSpan={1}>
                                      S/N
                                    </th>
                                    <th className="p-2" colSpan={5}>
                                      Description
                                    </th>
                                    <th
                                      colSpan={1}
                                      style={{ visibility: "hidden" }}
                                    >
                                      Template
                                    </th>
                                    <th className="p-2" colSpan={2}>
                                      Upload
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {companyDocs.length > 0
                                    ? companyDocs.map((item, index) => (
                                        <tr className="border-bottom">
                                          <td
                                            className="text-center p-2"
                                            colSpan={1}
                                          >
                                            {index + 1}
                                          </td>
                                          <td
                                            className="p-2 border-start"
                                            colSpan={5}
                                          >
                                            {item.ans == true ? (
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                [Required]
                                              </span>
                                            ) : null}{" "}
                                            {item.question}{" "}
                                          </td>
                                          <td
                                            className="text-center p-2"
                                            colSpan={1}
                                            style={{ visibility: "hidden" }}
                                          >
                                            <a href={""}>
                                              <img
                                                src={`${getImageSource(
                                                  ImageConstants.DOWNLOADWORD
                                                )}`}
                                                style={{ width: "30px" }}
                                                alt="logo"
                                              />
                                            </a>
                                          </td>
                                          <td colSpan={1}> </td>
                                          <td className="p-2" colSpan={2}>
                                            {isViewOnly && item.documentKey ? (
                                              <div>
                                                <div
                                                  className="doument-preview-wrapper text-center mr-2"
                                                  onClick={() =>
                                                    onClickDocumentPreview(
                                                      item,
                                                      index,
                                                      "companyDocs"
                                                    )
                                                  }
                                                >
                                                  <div className="doument-preview position-relative">
                                                    <div className="text-center">
                                                      {item.isLoading &&
                                                      item?.loadingKey ===
                                                        "oldDocument" ? (
                                                        <div className="">
                                                          <FontAwesomeIcon
                                                            className="icon "
                                                            spin
                                                            icon={faSpinner}
                                                          />
                                                        </div>
                                                      ) : (
                                                        <div className="">
                                                          <FontAwesomeIcon
                                                            className="icon"
                                                            icon={faFileAlt}
                                                          />
                                                        </div>
                                                      )}
                                                    </div>
                                                    {isEdit ? (
                                                      <FontAwesomeIcon
                                                        onClick={(e) =>
                                                          onRemoveDocument(
                                                            e,
                                                            index,
                                                            "companyDocs"
                                                          )
                                                        }
                                                        className="remove-icon cursor-pointer"
                                                        icon={faClose}
                                                      />
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            ) : isViewOnly && !isEdit ? (
                                              <div className="text-secondary pt-2">
                                                No file
                                              </div>
                                            ) : (
                                              <input
                                                style={{
                                                  width: "22vw",
                                                  fontSize: "10px",
                                                }}
                                                type="file"
                                                accept=".pdf"
                                                className={`form-control custom-file-input-bid ${
                                                  errorKey === "companyDocs" &&
                                                  errorIndex === index
                                                    ? "invalid-input"
                                                    : ""
                                                }`}
                                                onChange={(event) =>
                                                  onSelectFile(
                                                    event,
                                                    index,
                                                    "company"
                                                  )
                                                }
                                              />
                                            )}
                                          </td>
                                        </tr>
                                      ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                            {/* {companyDocs.length > 0
                              ? companyDocs.map((item, index) => (
                                  <div className="px-3 pt-4">
                                    <div className="">
                                      <label>
                                        {item.ans == true ? (
                                          <span
                                            style={{
                                              color: "red",
                                              fontWeight: "600",
                                            }}
                                          >
                                            [Required]
                                          </span>
                                        ) : null}{" "}
                                        {index + 1}. {item.question}{" "}
                                      </label>
                                      <div className="pt-2 px-4">
                                        {isViewOnly && item.documentKey ? (
                                          <div>
                                            <div
                                              className="doument-preview-wrapper text-center mr-2"
                                              onClick={() =>
                                                onClickDocumentPreview(
                                                  item,
                                                  index,
                                                  "companyDocs"
                                                )
                                              }
                                            >
                                              <div className="doument-preview position-relative">
                                                <div className="text-center">
                                                  {item.isLoading &&
                                                  item?.loadingKey ===
                                                    "oldDocument" ? (
                                                    <div className="">
                                                      <FontAwesomeIcon
                                                        className="icon "
                                                        spin
                                                        icon={faSpinner}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div className="">
                                                      <FontAwesomeIcon
                                                        className="icon"
                                                        icon={faFileAlt}
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                                {isEdit ? (
                                                  <FontAwesomeIcon
                                                    onClick={(e) =>
                                                      onRemoveDocument(
                                                        e,
                                                        index,
                                                        "companyDocs"
                                                      )
                                                    }
                                                    className="remove-icon cursor-pointer"
                                                    icon={faClose}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        ) : isViewOnly && !isEdit ? (
                                          <div className="text-secondary pt-2">
                                            No file
                                          </div>
                                        ) : (
                                          <input
                                            type="file"
                                            accept=".pdf"
                                            className={`form-control custom-file-input-bid ${
                                              errorKey === "companyDocs" &&
                                              errorIndex === index
                                                ? "invalid-input"
                                                : ""
                                            }`}
                                            onChange={(event) =>
                                              onSelectFile(
                                                event,
                                                index,
                                                "company"
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))
                              : null} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="pt-4 text-right">
              {/* <button type="button" className="btn btn-md btn-outline-primary mr-2">Review Submission</button> */}
              {!isViewOnly || isEdit ? (
                <button
                  onClick={submitBidDetails}
                  type="button"
                  className="btn btn-md btn-primary mr-2"
                >
                  {isEdit ? "Update Proposal" : "Submit Proposal"}
                </button>
              ) : (
                ""
              )}
              {props.isAward && tender?.tenderStatus != "AWARDED" ? (
                <button
                  onClick={onClickAwardButton}
                  type="button"
                  className="btn btn-md btn-primary mr-2"
                >
                  Award Tender
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          {showToaster ? (
            <ToasterComponent type={toaster?.type} message={toaster?.message} />
          ) : (
            ""
          )}
          {showConfirmModal ? (
            <AppConfirm
              img={getImageSource(ImageConstants.TICK_MARK)}
              okButtonStyle=""
              title="A $12.90 fee applies for submitting your proposal."
              close={closeConfirm}
              onOk={confirmSubmitBirDetails}
              cancelButtonText="No"
              okButtonText={"Proceed to Secure Checkout"}
            />
          ) : (
            ""
          )}

          {showAwardConfirmModal ? (
            <AppConfirm
              img={getImageSource(ImageConstants.TICK_MARK)}
              okButtonStyle=""
              title="Do you want to make this tender as Awarded ?"
              close={closeConfirm}
              onOk={confirmAwardTender}
              cancelButtonText="No"
              okButtonText={"Yes"}
            />
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

export default CreateBid;
