import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, forwardRef, useEffect } from "react";
import CounterView from "../counter/counter-component";
import CommonUtils from "../../../utility/common-utilities";
import Accordion from "react-bootstrap/Accordion";
import Urls from "../../../apis/url";
import TenderAPI from "../../../apis/tender";
import VendorCards from "../../cards/vendor-list-card/vendor-add-and-remove-cards";
import VendorFilter from "../../cards/vendor-list-card/vendor-filter";
import Loading from "../../common/loading/loading";
import VendorCard from "../../cards/vendor-list-card/vendor-card";

const TenderTerms = (props) => {
  let token =window.localStorage.getItem('user')
  const [payload, setPayload] = useState({});
  const [errorKey, setErrorKey] = useState("");
  const [praposal, setIsPraposal] = useState(false);
  const [errorAmount, setIsErrorAmount] = useState(false);
  const [ActiveKey, SetIsActiveKey] = useState(["0", "1", "2",'3','4']);
  const [btndisable,setbtndisable] = useState(false)
  const Contract = {
    Term: "contractTerm",
    Extension: "contractExtension",
  };
  const [selectedVendorList, setSelectedVendorList] = useState([])

  const ValidateTenderDeposit = (amount) => {
    if (isNaN(amount) || amount <= 0 || amount > 1000) {
      setIsErrorAmount(true);
      return;
    }
    let inputStr = amount.toString();
    if (inputStr.includes(".") && inputStr.split(".")[1].length > 2) {
      setIsErrorAmount(true);
      return;
    } else {
      if (praposal) {
        setIsErrorAmount(false);
        handlePayloadChange({ selected: true, amount }, "tenderDeposit");
        return;
      }
    }
  };

  useEffect(() => {
    setPayload(props.payload || {});
  }, []);

  const handlePayloadChange = (value, key) => {
    let payloadObj = { ...payload };
    payloadObj[key] = value;
    console.log(payloadObj);
    setPayloadObject(payloadObj);
  };

  const handleSelectVendor = (v) => {
    setSelectedVendorList(v)
  }

  const Fileupload =(event)=>{
      let file =event.target.files[0] 
      const formData = new FormData();
      formData.append("file", file);
      setbtndisable(true)

     
       fetch(`${Urls.BASE_URL}${Urls.UPLOADFILE}`, {
         method: 'POST',
         headers:{
           authorization: token,
         },
         body: formData,  
       })
      // TenderAPI.UpladAfile(formData)
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          setbtndisable(false)
          let payloadObj = { ...payload };
          payloadObj.pdfDocumentKey=data.pdfDocumentKey
          payloadObj.pdfDocumentUrl=data.pdfDocumentUrl
          console.log(payloadObj);
          setPayloadObject(payloadObj);
        })
        .catch(error => {
          // alert(error.message);
          console.error('Error:', error);
          return
          // Handle error
        });
      // let payloadObj = { ...payload};
      // payloadObj.file =file
      // console.log(payloadObj,"code")
      // console.log(file,"filesdata")
      // setPayloadObject(payloadObj);
  }

  const setPayloadObject = (payloadObj) => {
    console.log(payloadObj,"222222")
    setPayload(payloadObj);
    setErrorKey("");
    props.emitChanges && props.emitChanges(payloadObj, "7");
  };

  const onSubmit = () => {
    if (errorAmount) {
      return;
    }

    if(selectedVendorList) {
      let vendorIds = selectedVendorList.map(v => v.id)
      payload["vendorIds"] = vendorIds
    }
    if (!payload.contractTerm || !payload.contractExtension) {
      payload["contractTerm"] = 1;
      payload["contractExtension"] = 0;
    }

    if (!praposal) {
      payload["tenderDeposit"] = { selected: false };
    }
    console.log(payload,"payloadpayloadpayload")
    let validation = CommonUtils.validateCreateTenderPayload(payload, 7);
    if (validation?.error) {
      setErrorKey(validation.key);
      return;
    }
    props.emitChanges && props.emitChanges(payload, "7");
    props.onSubmit();
  };

  const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
    <button className="obx-date-picker-btn" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  return (
    <div>
      <span className="h6 header_title_caption fs-5">Terms Of Tender</span>
      <hr></hr>
      <Accordion activeKey={ActiveKey}>
        <Accordion.Item eventKey="0" className="mt-2 border-0">
          <Accordion.Header
            className="card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("0");
            }}
          >
            1. Publication Method
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <div
              className={`card mt-3 header_title_caption ${
                errorKey === "selectedTender" ? "invalid-input" : ""
              } `}
            >
              <div className="card-body">
                <div className="row">
                  <div class="col ">
                    <div
                      className="form-check"
                      onClick={() =>
                        handlePayloadChange("OPEN", "selectedTender")
                      }
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={payload.selectedTender === "OPEN"}
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Open Tender
                      </label>
                      <div className="mt-2 ">
                        <small>
                          Choose this option if you would like to publish the
                          tender on our website directory and make it visible to
                          all our vendors
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="col ">
                    <div
                      className="form-check"
                      onClick={() =>
                        handlePayloadChange("CLOSED", "selectedTender")
                      }
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioClosed"
                        checked={payload.selectedTender === "CLOSED"}
                      />
                      <label class="form-check-label" for="flexRadioClosed">
                        Closed Tender
                      </label>
                      <div className="mt-2">
                        <small>
                          Choose this option if you would like to restrict
                          applicants to a pre-selected list.
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              payload.selectedTender === "CLOSED" &&
                <div className={`card mt-3`}>
                  <div  className="card-header">
                    <h5 className="card-title">Select Vendor</h5>
                  </div>
                  <div className="card-body">
                    <VendorCard
                      handleSelectVendor={handleSelectVendor}
                      selectedVendorList={selectedVendorList}
                    />
                  </div>
                </div>
            }
            
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="border-0 mt-2">
          <Accordion.Header
            className="card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("1");
            }}
          >
            2. Key Dates
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <div
              className={`card mt-4 header_title_caption  ${`${
                errorKey === "publicationDate" || errorKey === "submissionDate"
                  ? "invalid-input"
                  : ""
              }`}`}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label htmlFor="formControlInput1" className="form-label">
                        Publication Date
                      </label>
                      <div>
                      <DatePicker
                        selected={payload.publicationDate}
                        showTimeSelect
                        minDate={new Date()}
                        closeOnScroll={true}
                        timeFormat="HH:mm"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        onChange={(date) =>
                          handlePayloadChange(date, "publicationDate")
                        }
                        customInput={
                          <input
                            type="text" // Change to text for consistent styling
                            className={`form-control w-100 ${
                              errorKey === "publicationDate" ? "invalid-input" : ""
                            }`}
                            readOnly // Prevent manual input since it's a date picker
                            value={payload.publicationDate ? payload.publicationDate.toLocaleString() : ""}
                            placeholder="Select Publication Date"
                          />
                        }
                      />
                      </div>
                     
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3">
                      <label htmlFor="formControlInput2" className="form-label">
                        Submission Deadline
                      </label>
                      <div>
                      <DatePicker
                        selected={payload.submissionDate}
                        showTimeSelect
                        minDate={new Date()}
                        closeOnScroll={true}
                        timeFormat="HH:mm"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        onChange={(date) =>
                          handlePayloadChange(date, "submissionDate")
                        }
                        customInput={
                          <input
                            type="text" // Change to text for consistent styling
                            className={`form-control w-100 ${
                              errorKey === "submissionDate" ? "invalid-input" : ""
                            }`}
                            readOnly // Prevent manual input since it's a date picker
                            value={payload.submissionDate ? payload.submissionDate.toLocaleString() : ""}
                            placeholder="Select Submission Deadline"
                          />
                        }
                      />
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="border-0 mt-2 ">
          <Accordion.Header
            className="card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("2");
            }}
          >
            3. Duration of Contract
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <div
              className={`card mt-4 header_title_caption ${
                errorKey === "contractTerm" || errorKey === "contractExtension"
                  ? "invalid-input"
                  : ""
              }`}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label htmlFor="formControlInput2" className="form-label">
                        Contract Term Years
                      </label>
                      <div className="row">
                        <div className="col-6">
                          <CounterView
                            leftButtonText="-"
                            rightButtonText="+"
                            contractType={Contract.Term}
                            inputValue={payload?.contractTerm || "1"}
                            onInputChange={(value) =>
                              handlePayloadChange(value, "contractTerm")
                            }
                          />
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3">
                      <label htmlFor="formControlInput2" className="form-label">
                        Contract Extension Years
                      </label>
                      <div className="row">
                        <div className="col-6">
                          <CounterView
                            leftButtonText="-"
                            rightButtonText="+"
                            contractType={Contract.Extension}
                            inputValue={payload?.contractExtension || "0"}
                            onInputChange={(value) =>
                              handlePayloadChange(value, "contractExtension")
                            }
                          />
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" className="border-0 mt-2 ">
          <Accordion.Header
            className="card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("3");
            }}
          >
            4. Tender Deposit
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <div
              className={`card mt-4 header_title_caption ${
                errorKey === "contractTerm" || errorKey === "contractExtension"
                  ? "invalid-input"
                  : ""
              }`}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="toggle-bar-wrap">
                      <span>
                        Select if tenderer must submit a tender deposit :
                      </span>
                      <input
                        type="checkbox"
                        checked={praposal}
                        onClick={() => {
                          setIsPraposal(!praposal);
                        }}
                      />
                    </div>
                    {praposal && (
                      <div className="mt-3 d-flex align-items-center">
                        <span>
                          Enter the value of the deposit required :<b> $</b>
                        </span>
                        <input
                          type="number"
                          style={{ width: "10rem" }}
                          className={`form-control ms-2 ${
                            errorAmount ? `pincode_verification` : ``
                          }`}
                          onChange={(e) => {
                            ValidateTenderDeposit(+e.target.value);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4" className="border-0 mt-2 ">
          <Accordion.Header
            className="card-header header_title_caption itembutton"
            onClick={() => {
              SetIsActiveKey("4");
            }}
          >
            4. Upload Additional file
          </Accordion.Header>
          <Accordion.Body className="border border-dark border-top-0 rounded-bottom">
            <div
              className={`card mt-4 header_title_caption ${
                errorKey === "contractTerm" || errorKey === "contractExtension"
                  ? "invalid-input"
                  : ""
              }`}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="toggle-bar-wrap">
                      <span>
                         Upload additional File
                      </span>
                      <input
                        type="file"
                        checked={praposal}
                        accept=".pdf, .doc, .docx"
                        onChange={
                            Fileupload
                        }
                        // onClick={() => {
                        //   setIsPraposal(!praposal);
                        // }}
                      />
                    </div>
                    {/* {praposal && (
                      <div className="mt-3 d-flex align-items-center">
                        <span>
                          Enter the value of the deposit required :<b> $</b>
                        </span>
                        <input
                          type="number"
                          style={{ width: "10rem" }}
                          className={`form-control ms-2 ${
                            errorAmount ? `pincode_verification` : ``
                          }`}
                          onChange={(e) => {
                            ValidateTenderDeposit(+e.target.value);
                          }}
                        />
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {/* <div
        className={`card mt-3 header_title_caption ${
          errorKey === "selectedTender" ? "invalid-input" : ""
        } `}
      >
        <div className="card-header tendercreate_header-color">
          1. Publication Method
        </div>
        <div className="card-body">
          <div className="row">
            <div class="col ">
              <div
                className="form-check"
                onClick={() => handlePayloadChange("OPEN", "selectedTender")}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  checked={payload.selectedTender === "OPEN"}
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Open Tender
                </label>
                <div className="mt-2 ">
                  <small>
                    Choose this option if you would like to publish the tender
                    on our website directory and make it visible to all our
                    vendors
                  </small>
                </div>
              </div>
            </div>
            <div class="col ">
              <div
                className="form-check"
                onClick={() => handlePayloadChange("CLOSED", "selectedTender")}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  checked={payload.selectedTender === "CLOSED"}
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Closed Tender
                </label>
                <div className="mt-2">
                  <small>
                    Choose this option if you would like to restrict applicants
                    to a pre-selected list.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`card mt-4 header_title_caption ${`${
          errorKey === "publicationDate" || errorKey === "submissionDate"
            ? "invalid-input"
            : ""
        }`}`}
      >
        <div className="card-header tendercreate_header-color">
          2. Key Dates (optional)
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <div className="mb-3">
                <label htmlFor="formControlInput1" className="form-label">
                  Publication date
                </label>
                <DatePicker
                  selected={payload.publicationDate}
                  showTimeSelect
                  minDate={new Date()}
                  ccloseOnScroll={true}
                  timeFormat="HH:mm"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  onChange={(date) =>
                    handlePayloadChange(date, "publicationDate")
                  }
                  customInput={<CustomDatePicker />}
                />
              </div>
            </div>
            <div className="col">
              <div className="mb-3">
                <label htmlFor="formControlInput2" className="form-label">
                  Submission Deadline
                </label>

                <DatePicker
                  selected={payload.submissionDate}
                  showTimeSelect
                  minDate={new Date()}
                  closeOnScroll={true}
                  timeFormat="HH:mm"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  onChange={(date) =>
                    handlePayloadChange(date, "submissionDate")
                  }
                  customInput={<CustomDatePicker />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`card mt-4 header_title_caption ${
          errorKey === "contractTerm" || errorKey === "contractExtension"
            ? "invalid-input"
            : ""
        }`}
      >
        <div className="card-header tendercreate_header-color">
          3. Duration of Contract
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <div className="mb-3">
                <label htmlFor="formControlInput2" className="form-label">
                  Contract Term Years
                </label>
                <div className="row">
                  <div className="col-6">
                    <CounterView
                      leftButtonText="-"
                      rightButtonText="+"
                      contractType={Contract.Term}
                      inputValue={payload?.contractTerm || "1"}
                      onInputChange={(value) =>
                        handlePayloadChange(value, "contractTerm")
                      }
                    />
                  </div>
                  <div className="col"></div>
                  <div className="col"></div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="mb-3">
                <label htmlFor="formControlInput2" className="form-label">
                  Contract Extension Years
                </label>
                <div className="row">
                  <div className="col-6">
                    <CounterView
                      leftButtonText="-"
                      rightButtonText="+"
                      contractType={Contract.Extension}
                      inputValue={payload?.contractExtension || "0"}
                      onInputChange={(value) =>
                        handlePayloadChange(value, "contractExtension")
                      }
                    />
                  </div>
                  <div className="col"></div>
                  <div className="col"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="row mt-4">
        <div className="col">
          <button
            onClick={props.back}
            type="button"
            className="btn btn-md btn-light float-left"
          >
            Back
          </button>
        </div>
        <div className="col">
          <button
            onClick={onSubmit}
            type="button"
            className="btn btn-md btn-primary obx-btn-color float-end"
            disabled={btndisable}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default TenderTerms;
